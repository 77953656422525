import {
	GET_CURRENT_LTS_BALANCE,
	GET_CURRENT_LTS_BALANCE_ENERGY_DAO,
	GET_CURRENT_LTS_BALANCE_TREASURY_DAO,
	GET_CURRENT_USDN_BALANCE_ENERGY_DAO,
	GET_CURRENT_USDN_BALANCE_TREASURY_DAO,
} from '../actions/types';

const initialState = {
	isLoading: true,
	ltsBalance: 0,
	tdLtsBalance: 0,
	tdUsdnBalance: 0,
	edLtsBalance: 0,
	edUsdnBalance: 0,
	message: '',
};

const auth = (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case GET_CURRENT_LTS_BALANCE:
			return {
				...state,
				isLoading: false,
				ltsBalance: payload / 100000000,
			};
		case GET_CURRENT_LTS_BALANCE_TREASURY_DAO:
			return {
				...state,
				isLoading: false,
				tdLtsBalance: payload / 100000000,
			};
		case GET_CURRENT_USDN_BALANCE_TREASURY_DAO:
			return {
				...state,
				isLoading: false,
				tdUsdnBalance: payload / 1000000000000000000,
			};
		case GET_CURRENT_LTS_BALANCE_ENERGY_DAO:
			return {
				...state,
				isLoading: false,
				edLtsBalance: payload / 100000000,
			};
		case GET_CURRENT_USDN_BALANCE_ENERGY_DAO:
			return {
				...state,
				isLoading: false,
				edUsdnBalance: payload / 1000000000000000000,
			};

		default:
			return state;
	}
};

export default auth;
