//Auth
export const CONNECTED_WALLET_SUCCESS = 'CONNECTED_WALLET_SUCCESS';
export const CONNECTED_WALLET_FAIL = 'CONNECTED_WALLET_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const DISCONNECTED_WALLET = 'DISCONNECTED_WALLET';
export const USER_IS_WHITELISTED = 'USER_IS_WHITELISTED';
export const USER_IS_NOT_WHITELISTED = 'USER_IS_NOT_WHITELISTED';

// Council DAO
export const GET_PROPOSALS_SUCCESS = 'GET_PROPOSALS_SUCCESS';
export const GET_PROPOSALS_FAIL = 'GET_PROPOSALS_FAIL';
export const GET_COMMUNITIES_SUCCESS = 'GET_COMMUNITIES_SUCCESS';
export const GET_COMMUNITIES_FAIL = 'GET_COMMUNITIES_FAIL';
export const GET_COUNCIL_SUCCESS = 'GET_COUNCIL_SUCCESS';
export const GET_COUNCIL_FAIL = 'GET_COUNCIL_FAIL';
export const GET_SPECIFIC_PROPOSAL_SUCCESS = 'GET_SPECIFIC_PROPOSAL_SUCCESS';
export const GET_SPECIFIC_PROPOSAL_FAIL = 'GET_SPECIFIC_PROPOSAL_FAIL';
export const CREATE_PROPOSAL_SUCESS = 'CREATE_PROPOSAL_SUCESS';
export const CREATE_PROPOSAL_FAIL = 'CREATE_PROPOSAL_FAIL';
export const ADD_VOTE_SUCCESS = 'ADD_VOTE_SUCCESS';
export const ADD_VOTE_AGAINST = 'ADD_VOTE_AGAINST';
export const USER_IS_STAKER = 'USER_IS_STAKER';
export const USER_IS_NOT_STAKER = 'USER_IS_NOT_STAKER';
export const ADD_COMMUNIY_SUCCESS = 'ADD_COMMUNIY_SUCCESS';
export const ADD_COMMUNIY_FAIL = 'ADD_COMMUNIY_FAIL';
export const ADD_COUNCIL_SUCCESS = 'ADD_COUNCIL_SUCCESS';
export const ADD_COUNCIL_FAIL = 'ADD_COUNCIL_FAIL';

// Energy DAO
export const GET_PROPOSALS_SUCCESS_ENERGY_DAO = 'GET_PROPOSALS_SUCCESS_ENERGY_DAO';
export const GET_PROPOSALS_FAIL_ENERGY_DAO = 'GET_PROPOSALS_FAIL_ENERGY_DAO';
export const GET_COMMUNITIES_SUCCESS_ENERGY_DAO = 'GET_COMMUNITIES_SUCCESS_ENERGY_DAO';
export const GET_COMMUNITIES_FAIL_ENERGY_DAO = 'GET_COMMUNITIES_FAIL_ENERGY_DAO';
export const GET_COUNCIL_SUCCESS_ENERGY_DAO = 'GET_COUNCIL_SUCCESS_ENERGY_DAO';
export const GET_COUNCIL_FAIL_ENERGY_DAO = 'GET_COUNCIL_FAIL_ENERGY_DAO';
export const GET_SPECIFIC_PROPOSAL_SUCCESS_ENERGY_DAO = 'GET_SPECIFIC_PROPOSAL_SUCCESS_ENERGY_DAO';
export const GET_SPECIFIC_PROPOSAL_FAIL_ENERGY_DAO = 'GET_SPECIFIC_PROPOSAL_FAIL_ENERGY_DAO';
export const CREATE_PROPOSAL_SUCESS_ENERGY_DAO = 'CREATE_PROPOSAL_SUCESS_ENERGY_DAO';
export const CREATE_PROPOSAL_FAIL_ENERGY_DAO = 'CREATE_PROPOSAL_FAIL_ENERGY_DAO';
export const ADD_VOTE_SUCCESS_ENERGY_DAO = 'ADD_VOTE_SUCCESS_ENERGY_DAO';
export const ADD_VOTE_AGAINST_ENERGY_DAO = 'ADD_VOTE_AGAINST_ENERGY_DAO';
export const ADD_COMMUNIY_SUCCESS_ENERGY_DAO = 'ADD_COMMUNIY_SUCCESS_ENERGY_DAO';
export const ADD_COMMUNIY_FAIL_ENERGY_DAO = 'ADD_COMMUNIY_FAIL_ENERGY_DAO';
export const ADD_COUNCIL_SUCCESS_ENERGY_DAO = 'ADD_COUNCIL_SUCCESS_ENERGY_DAO';
export const ADD_COUNCIL_FAIL_ENERGY_DAO = 'ADD_COUNCIL_FAIL_ENERGY_DAO';

// Staking
export const STAKE_SUCCESS = 'STAKE_SUCCESS';
export const STAKE_FAIL = 'STAKE_FAIL';
export const GET_MY_TOTAL_STAKED_PER_WALLET_SUCCESS = 'GET_MY_TOTAL_STAKED_PER_WALLET_SUCCESS';
export const GET_MY_TOTAL_STAKED_PER_WALLET_FAIL = 'GET_MY_TOTAL_STAKED_PER_WALLET_FAIL';
export const GET_MY_STAKING_DETAILS_SUCCESS = 'GET_MY_STAKING_DETAILS_SUCCESS';
export const GET_MY_STAKING_DETAILS_FAIL = 'GET_MY_STAKING_DETAILS_FAIL';
export const DEPOSIT_STAKE_SUCCESS = 'DEPOSIT_STAKE_SUCCESS';
export const DEPOSIT_STAKE_FAIL = 'DEPOSIT_STAKE_SUCCESS';
export const UNSTAKE_SUCCESS = 'UNSTAKE_SUCCESS';
export const UNSTAKE_FAIL = 'UNSTAKE_FAIL';
export const CALCULATE_REWARD_SUCCESS = 'CALCULATE_REWARD_SUCCESS';
export const CALCULATE_REWARD_FAIL = 'CALCULATE_REWARD_FAIL';
export const WITHDRAW_STAKE_SUCCESS = 'WITHDRAW_STAKE_SUCCESS';
export const WITHDRAW_STAKE_FAIL = 'CALCULATE_REWARD_FAIL';
export const WITHRAW_REWARD_SUCCESS = 'WITHRAW_REWARD_SUCCESS';
export const WITHRAW_REWARD_FAIL = 'WITHRAW_REWARD_FAIL';

//Vesting state
export const ADD_LOCKUP_SUCCESS = 'ADD_LOCKUP_SUCCESS';
export const ADD_LOCKUP_FAIL = 'ADD_LOCKUP_FAIL';
export const GET_ALL_VESTORS_SUCCESS = 'GET_ALL_VESTORS_SUCCESS';
export const GET_ALL_VESTORS_FAIL = 'GET_ALL_VESTORS_FAIL';
export const GET_MY_VESTING_DETAILS_SUCCESS = 'GET_MY_VESTING_DETAILS_SUCCESS';
export const GET_MY_VESTING_DETAILS_FAIL = 'GET_MY_VESTING_DETAILS_FAIL';
export const CLAIM_LOCKUPS_SUCCESS = 'CLAIM_LOCKUPS_SUCCESS';
export const CLAIM_LOCKUPS_FAIL = 'CLAIM_LOCKUPS_FAIL';

//LTS
export const GET_CURRENT_LTS_BALANCE = 'GET_CURRENT_LTS_BALANCE';
export const GET_CURRENT_LTS_BALANCE_FAIL = 'GET_CURRENT_LTS_BALANCE_FAIL';
export const GET_CURRENT_LTS_BALANCE_TREASURY_DAO = 'GET_CURRENT_LTS_BALANCE_TREASURY_DAO';
export const GET_CURRENT_LTS_BALANCE_FAIL_TREASURY_DAO =
	'GET_CURRENT_LTS_BALANCE_FAIL_TREASURY_DAO';
export const GET_CURRENT_LTS_BALANCE_ENERGY_DAO = 'GET_CURRENT_LTS_BALANCE_ENERGY_DAO';
export const GET_CURRENT_LTS_BALANCE_FAIL_ENERGY_DAO = 'GET_CURRENT_LTS_BALANCE_FAIL_ENERGY_DAO';
export const GET_CURRENT_USDN_BALANCE_TREASURY_DAO = 'GET_CURRENT_USDN_BALANCE_TREASURY_DAO';
export const GET_CURRENT_USDN_BALANCE_FAIL_TREASURY_DAO =
	'GET_CURRENT_USDN_BALANCE_FAIL_TREASURY_DAO';
export const GET_CURRENT_USDN_BALANCE_ENERGY_DAO = 'GET_CURRENT_USDN_BALANCE_ENERGY_DAO';
export const GET_CURRENT_USDN_BALANCE_FAIL_ENERGY_DAO = 'GET_CURRENT_USDN_BALANCE_FAIL_ENERGY_DAO';
