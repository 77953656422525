import {
	CALCULATE_REWARD_FAIL,
	CALCULATE_REWARD_SUCCESS,
	DEPOSIT_STAKE_FAIL,
	DEPOSIT_STAKE_SUCCESS,
	GET_MY_STAKING_DETAILS_FAIL,
	GET_MY_STAKING_DETAILS_SUCCESS,
	GET_MY_TOTAL_STAKED_PER_WALLET_FAIL,
	GET_MY_TOTAL_STAKED_PER_WALLET_SUCCESS,
} from '../actions/types';

const initialState = {
	isLoading: true,
	totalStakeSupply: 0,
	myStakingDetails: null,
	message: '',
	estimatedRewardPerDay: 0,
};

const staking = (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case GET_MY_TOTAL_STAKED_PER_WALLET_SUCCESS:
			return {
				...state,
				isLoading: false,
				totalStakeSupply: payload,
			};
		case GET_MY_TOTAL_STAKED_PER_WALLET_FAIL:
			return {
				...state,
				isLoading: false,
				message: 'Something went wrong, maybe try later',
			};
		case GET_MY_STAKING_DETAILS_SUCCESS:
			return {
				...state,
				isLoading: false,
				myStakingDetails: payload,
			};
		case CALCULATE_REWARD_FAIL:
		case GET_MY_STAKING_DETAILS_FAIL:
			return {
				...state,
				isLoading: false,
				message: 'Something went wrong, maybe try againg later ',
			};
		case DEPOSIT_STAKE_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case DEPOSIT_STAKE_FAIL:
			return {
				...state,
				isLoading: false,
				message: 'The staking process went wrong, maybe try againg later ',
			};
		case CALCULATE_REWARD_SUCCESS:
			return {
				...state,
				isLoading: false,
				estimatedRewardPerDay: payload,
			};
		default:
			return state;
	}
};

export default staking;
