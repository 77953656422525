import {
	CONNECTED_WALLET_FAIL,
	CONNECTED_WALLET_SUCCESS,
	USER_IS_NOT_STAKER,
	USER_IS_NOT_WHITELISTED,
	USER_IS_STAKER,
	USER_IS_WHITELISTED,
	USER_LOADED,
} from '../actions/types';

const initialState = {
	isLoading: true,
	isAuthenticated: false,
	isWhitelisted: false,
	isStaker: false,
	accountId: null,
	message: '',
};

const auth = (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case CONNECTED_WALLET_SUCCESS:
			return {
				...state,
				isLoading: false,
				isAuthenticated: true,
				accountId: payload,
			};
		case CONNECTED_WALLET_FAIL:
			return {
				...state,
				isLoading: false,
				isAuthenticated: false,
				accountId: null,
			};
		case USER_LOADED:
			return {
				...state,
				isLoading: false,
				isAuthenticated: true,
				accountId: payload,
			};
		case USER_IS_NOT_WHITELISTED:
		case USER_IS_WHITELISTED:
			return {
				...state,
				isLoading: false,
				isWhitelisted: payload,
			};
		case USER_IS_STAKER:
			return {
				...state,
				isLoading: false,
				isStaker: payload,
			};
		case USER_IS_NOT_STAKER:
			return {
				...state,
				isLoading: false,
				isStaker: payload,
			};
		default:
			return state;
	}
};

export default auth;
