import { getLTSBalance, getLTSBalanceByAccountId, getUSDNBalanceByAccountId } from '../../utils';
import {
	GET_CURRENT_LTS_BALANCE,
	GET_CURRENT_LTS_BALANCE_ENERGY_DAO,
	GET_CURRENT_LTS_BALANCE_TREASURY_DAO,
	GET_CURRENT_USDN_BALANCE_ENERGY_DAO,
	GET_CURRENT_USDN_BALANCE_TREASURY_DAO,
} from './types';

export const getBalanceLTS = async (dispatch) => {
	getLTSBalance().then((balance) => {
		dispatch({
			type: GET_CURRENT_LTS_BALANCE,
			payload: balance,
		});
	});
};

export const getCurrentBalanceTD = async (dispatch) => {
	getLTSBalanceByAccountId('treasurydao.near').then((res) => {
		dispatch({
			type: GET_CURRENT_LTS_BALANCE_TREASURY_DAO,
			payload: res,
		});
	});
};

export const getCurrentBalanceED = async (dispatch) => {
	getLTSBalanceByAccountId('energydao.near').then((res) => {
		dispatch({
			type: GET_CURRENT_LTS_BALANCE_ENERGY_DAO,
			payload: res,
		});
	});
};

export const getCurrentUsdnBalanceTD = async (dispatch) => {
	getUSDNBalanceByAccountId('treasurydao.near').then((res) => {
		dispatch({
			type: GET_CURRENT_USDN_BALANCE_TREASURY_DAO,
			payload: res,
		});
	});
};

export const getCurrentUsdnBalanceED = async (dispatch) => {
	getUSDNBalanceByAccountId('energydao.near').then((res) => {
		dispatch({
			type: GET_CURRENT_USDN_BALANCE_ENERGY_DAO,
			payload: res,
		});
	});
};
