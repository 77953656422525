export const homeMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
	governance: {
		id: 'governance',
		text: 'Governance',
		path: '/governance',
		icon: 'Groups',
		subMenu: null,
	},
	staking: {
		id: 'staking',
		text: 'Staking',
		path: '/staking',
		icon: 'StackedLineChart',
		subMenu: null,
	},
	vesting: {
		id: 'vesting',
		text: 'Vesting',
		path: '/vesting',
		icon: 'Lock',
		subMenu: null,
	},
	swap: {
		id: 'swap',
		text: 'Swap',
		path: '/swap',
		icon: 'SwapHoriz',
		subMenu: null,
	},
};

export const demoPages = {};

export const layoutMenu = {};

export const componentsMenu = {};

export const productsMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
