import React, { useContext, useEffect, useLayoutEffect, useRef, FC } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';

import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { demoPages, layoutMenu } from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import { accountId, addLockup, getLTSBalance } from '../utils';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { GET_PROPOSALS_SUCCESS } from '../redux/actions/types';
import { getCurrentUser } from '../redux/actions/auth';
import { getBalanceLTS } from '../redux/actions/myWallet';

// Near connection

const App: FC<{
	auth: any;
	GetMe: any;
	getMyLTS: any;
}> = ({ auth, GetMe, getMyLTS }) => {
	getOS();

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		GetMe();
		getMyLTS();
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	// @ts-ignore
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				<TourProvider
					steps={steps}
					styles={styles}
					showNavigation={false}
					showBadge={false}
				>
					<div
						ref={ref}
						className='app'
						style={{
							backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
							zIndex: fullScreenStatus ? 1 : undefined,
							overflow: fullScreenStatus ? 'scroll' : undefined,
						}}
					>
						<Routes>
							<Route path='*' element={<Aside auth={auth} />} />
						</Routes>
						<Wrapper />
					</div>

					<Portal id='portal-notification'>
						<ReactNotifications />
					</Portal>
				</TourProvider>
			</ToastProvider>
		</ThemeProvider>
	);
};

App.propTypes = {
	GetMe: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

function mapStateToProps(state: any) {
	return {
		auth: state.auth,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		GetMe: () => getCurrentUser(dispatch),
		getMyLTS: () => getBalanceLTS(dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
