import React, { lazy } from 'react';
import { dashboardMenu } from '../menu';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	STAKING: lazy(() => import('../pages/presentation/staking/StakingPage')),
	VESTING: lazy(() => import('../pages/presentation/vesting/Vesting')),
	SWAP: lazy(() => import('../pages/presentation/swap/Swap')),
	GOVERNANCE: lazy(() => import('../pages/presentation/governance/GovernancePage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: dashboardMenu.governance.path,
		element: <LANDING.GOVERNANCE />,
		exact: true,
	},
	{
		path: dashboardMenu.staking.path,
		element: <LANDING.STAKING />,
		exact: true,
	},
	{
		path: dashboardMenu.vesting.path,
		element: <LANDING.VESTING />,
		exact: true,
	},
	{
		path: dashboardMenu.swap.path,
		element: <LANDING.SWAP />,
		exact: true,
	},
	/** ************************************************** */

	/**
	 * Pages
	 */

	/**
	 * END - Pages
	 */

	/**
	 * Auth Page
	 */

	/**
	 * App
	 */

	/**
	 * END - App
	 */

	/** ************************************************** */
];
const contents = [...presentation];

export default contents;
