import {
	CONNECTED_WALLET_FAIL,
	CONNECTED_WALLET_SUCCESS,
	USER_IS_NOT_STAKER,
	USER_IS_NOT_WHITELISTED,
	USER_IS_STAKER,
	USER_IS_WHITELISTED,
	USER_LOADED,
} from './types';
import { login, accountId, getStakers } from '../../utils';
import { isWhitelisted } from '../../utils/isWhitelisted';

export const connectWallet = async (dispatch) => {
	try {
		login();
		let myAccountId = await accountId().accountId;
		if (myAccountId) {
			dispatch({
				type: CONNECTED_WALLET_SUCCESS,
				payload: myAccountId,
			});

			if (isWhitelisted(myAccountId)) {
				dispatch({
					type: USER_IS_WHITELISTED,
					payload: true,
				});
			} else {
				dispatch({
					type: USER_IS_NOT_WHITELISTED,
					payload: false,
				});
			}
		} else {
			dispatch({
				type: CONNECTED_WALLET_FAIL,
			});
			dispatch({
				type: USER_IS_NOT_WHITELISTED,
				payload: false,
			});
		}
	} catch (error) {
		const errors = error.response.data.errors;
		if (errors) {
			errors.forEach((e) => {
				console.error(e.message);
			});
		}

		dispatch({
			type: CONNECTED_WALLET_FAIL,
		});
	}
};

export const getCurrentUser = async (dispatch) => {
	try {
		let myAccountId = await accountId().accountId;
		if (myAccountId) {
			dispatch({
				type: USER_LOADED,
				payload: myAccountId,
			});
			if (isWhitelisted(myAccountId)) {
				dispatch({
					type: USER_IS_WHITELISTED,
					payload: true,
				});
			} else {
				dispatch({
					type: USER_IS_NOT_WHITELISTED,
					payload: false,
				});
			}

			getStakers().then((result) => {
				console.log('this is for olfa', result);
				if (result.includes(myAccountId)) {
					dispatch({
						type: USER_IS_STAKER,
						payload: true,
					});
				} else {
					dispatch({
						type: USER_IS_NOT_STAKER,
						payload: false,
					});
				}
			});
		} else {
			dispatch({
				type: CONNECTED_WALLET_FAIL,
			});
			dispatch({
				type: USER_IS_NOT_WHITELISTED,
				payload: false,
			});
		}
	} catch (error) {
		const errors = error.response.data.errors;
		if (errors) {
			errors.forEach((e) => {
				console.error(e.message);
			});
		}

		dispatch({
			type: CONNECTED_WALLET_FAIL,
		});
	}
};
