import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';
import { initContract } from './utils';
import { Provider } from 'react-redux';

import store from './redux/store';

const children = (
	<AuthContextProvider>
		<ThemeContextProvider>
			<Router>
				<React.StrictMode>
					<Provider store={store}>
						<App />
					</Provider>
				</React.StrictMode>
			</Router>
		</ThemeContextProvider>
	</AuthContextProvider>
);

const container = document.getElementById('root');
const root = createRoot(container as Element);

initContract()
	.then(() => {
		root.render(children);
	})
	.catch(console.error);

reportWebVitals();
