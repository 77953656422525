import React, { useState, FC } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import USERS from '../../../common/data/userDummyData';
import Avatar from '../../../components/Avatar';
import { connectWallet } from '../../../redux/actions/auth';
import { isWhitelisted } from '../../../utils/isWhitelisted';

const CommonHeaderWallet: FC<{
	auth: any;
	login: any;
}> = ({ auth, login }) => {
	const [state, setState] = useState<boolean>(false);

	return (
		<>
			<div
				className='col d-flex align-items-center cursor-pointer'
				onClick={() => setState(!state)}
				role='presentation'
			>
				{auth?.isAuthenticated ? (
					<>
						<div className='me-3'>
							<div className='text-end'>
								<div className='fw-bold fs-6 mb-0'>{auth?.accountId}</div>
								<div className='text-muted'>
									<small>
										{isWhitelisted(auth?.accountId)
											? 'Council member'
											: 'Community member'}
									</small>
								</div>
							</div>
						</div>
						<div className='position-relative'>
							<Avatar
								srcSet={USERS.CHLOE.srcSet}
								src={USERS.CHLOE.src}
								size={48}
								color={USERS.CHLOE.color}
							/>

							<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
								<span className='visually-hidden'>Online user</span>
							</span>
						</div>
					</>
				) : (
					<button className='btn btn-primary' onClick={() => login()}>
						Connect Wallet
					</button>
				)}
			</div>
		</>
	);
};

CommonHeaderWallet.propTypes = {
	login: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
};

function mapStateToProps(state: any) {
	return {
		auth: state.auth,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		login: () => connectWallet(dispatch),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(CommonHeaderWallet);
