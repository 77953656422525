import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import councilDAO from './reducers/councilDAO';
import staking from './reducers/staking';
import auth from './reducers/auth';
import vesting from './reducers/vesting';
import myWallet from './reducers/myWallet';
import energyDAO from './reducers/energyDAO';

import { combineReducers } from 'redux';

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
	councilDAO,
	energyDAO,
	staking,
	auth,
	vesting,
	myWallet,
});

const store = createStore(
	reducers,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
