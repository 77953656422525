import React, { FC, useState, useContext, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { demoPages } from '../../menu';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import { connectWallet } from '../../redux/actions/auth';
import { logout } from '../../utils';
import { isWhitelisted } from '../../utils/isWhitelisted';

const User: FC<{ auth: any }> = ({ auth }) => {
	const { userData, setUser } = useContext(AuthContext);

	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			{auth?.isAuthenticated ? (
				<>
					<Fragment>
						<div
							className={classNames('user', { open: collapseStatus })}
							role='presentation'
							onClick={() => setCollapseStatus(!collapseStatus)}
						>
							{/* <div className='user-avatar'>
								<img
									srcSet={userData?.srcSet}
									src={userData?.src}
									alt='Avatar'
									width={128}
									height={128}
								/>
							</div> */}
							<div className='user-info'>
								<div className='user-name d-flex align-items-center'>
									{auth?.accountId}
									<Icon icon='Verified' className='ms-1' color='info' />
								</div>
								<div className='user-sub-title'>
									{isWhitelisted(auth?.accountId)
										? 'Council member'
										: 'Community member'}
								</div>
							</div>
						</div>
						<DropdownMenu>
							<DropdownItem>
								<Button
									icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
									onClick={() => setDarkModeStatus(!darkModeStatus)}
									aria-label='Toggle fullscreen'
								>
									{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
								</Button>
							</DropdownItem>
						</DropdownMenu>

						<Collapse isOpen={collapseStatus} className='user-menu'>
							<nav aria-label='aside-bottom-user-menu'>
								<div className='navigation'>
									<div
										role='presentation'
										className='navigation-item cursor-pointer'
										onClick={() =>
											navigate(
												'',
												// @ts-ignore
												handleItem(),
											)
										}
									>
										<span className='navigation-link navigation-link-pill'>
											<span className='navigation-link-info'>
												<Icon
													icon='AccountBox'
													className='navigation-icon'
												/>
												<span className='navigation-text'>
													{t('menu:Profile')}
												</span>
											</span>
										</span>
									</div>
									<div
										role='presentation'
										className='navigation-item cursor-pointer'
										onClick={() => {
											setDarkModeStatus(!darkModeStatus);
											handleItem();
										}}
									>
										<span className='navigation-link navigation-link-pill'>
											<span className='navigation-link-info'>
												<Icon
													icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
													color={darkModeStatus ? 'info' : 'warning'}
													className='navigation-icon'
												/>
												<span className='navigation-text'>
													{darkModeStatus
														? t('menu:DarkMode')
														: t('menu:LightMode')}
												</span>
											</span>
										</span>
									</div>
								</div>
							</nav>
							<NavigationLine />
							<nav aria-label='aside-bottom-user-menu-2'>
								<div className='navigation'>
									<div
										role='presentation'
										className='navigation-item cursor-pointer'
									>
										<span className='navigation-link navigation-link-pill'>
											<span className='navigation-link-info'>
												<Icon icon='Logout' className='navigation-icon' />
												<span
													className='navigation-text'
													onClick={() => logout()}
												>
													{t('menu:Logout')}
												</span>
											</span>
										</span>
									</div>
								</div>
							</nav>
						</Collapse>
					</Fragment>
				</>
			) : (
				<></>
			)}
		</>
	);
};

User.propTypes = {
	auth: PropTypes.object.isRequired,
};

function mapStateToProps(state: any) {
	return {
		auth: state.auth,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(User);
