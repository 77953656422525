import { connect, Contract, keyStores, transactions, WalletConnection } from 'near-api-js';
import { v4 as uuidv4 } from 'uuid';

import getConfig from './config';

const nearConfig = getConfig('mainnet');

// Initialize contract & set global variables
export async function initContract() {
	// Initialize connection to the NEAR testnet
	const near = await connect(
		Object.assign(
			{ deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() } },
			nearConfig,
		),
	);

	// Initializing Wallet based Account. It can work with NEAR testnet wallet that
	// is hosted at https://wallet.testnet.near.org
	window.walletConnection = new WalletConnection(near);

	// Getting the Account ID. If still unauthorized, it's just empty string
	window.accountId = window.walletConnection.getAccountId();

	window.treasuryDAO = await new Contract(window.walletConnection.account(), 'treasurydao.near', {
		viewMethods: [
			'get_councils',
			'get_communities',
			'get_proposals',
			'get_specific_proposal',
			'get_stakers',
		],
		changeMethods: [
			'create_proposal',
			'add_vote',
			'check_staker',
			'check_member',
			'add_staker',
			'add_community',
			'add_council',
			'transfer',
			'fund',
		],
	});

	window.energyDAO = await new Contract(window.walletConnection.account(), 'energydao.near', {
		viewMethods: [
			'get_councils',
			'get_communities',
			'get_proposals',
			'get_specific_proposal',
			'get_stakers',
		],
		changeMethods: [
			'create_proposal',
			'add_vote',
			'check_staker',
			'check_member',
			'add_staker',
			'add_community',
			'add_council',
			'transfer',
			'fund',
		],
	});

	window.vesting = await new Contract(
		window.walletConnection.account(),
		'lightency_vesting.near',
		{
			viewMethods: ['get_all_vestors'],
			changeMethods: ['add_lockup', 'refresh'],
		},
	);

	window.stake = await new Contract(window.walletConnection.account(), 'staking_contract.near', {
		viewMethods: [],
		changeMethods: ['unstake', 'withdraw', 'stake'],
	});

	window.rewardPool = await new Contract(
		window.walletConnection.account(),
		'rewarder_contract.near',
		{
			viewMethods: ['get_totalstaked', 'get_data', 'calculaterewards'],
			changeMethods: ['update_reward', 'withdraw_reward'],
		},
	);

	window.lts = await new Contract(window.walletConnection.account(), 'lts_token.near', {
		viewMethods: ['ft_metadata', 'ft_balance_of'],
		changeMethods: ['ft_transfer'],
	});

	window.usdn = await new Contract(window.walletConnection.account(), 'usn', {
		viewMethods: ['ft_metadata', 'ft_balance_of'],
		changeMethods: ['ft_transfer'],
	});
}

export function logout() {
	window.walletConnection.signOut();
	// reload page
	window.location.replace(window.location.origin + window.location.pathname);
}

export function login() {
	// Allow the current app to make calls to the specified contract on the
	// user's behalf.
	// This works by creating a new access key for the user's account and storing
	// the private key in localStorage.
	window.walletConnection.requestSignIn(nearConfig.contractName);
}

export function signOutNearWallet() {
	window.walletConnection.signOut();
	// reload page
	window.location.replace(window.location.origin + window.location.pathname);
}

export function signInWithNearWallet() {
	// Allow the current app to make calls to the specified contract on the
	// user's behalf.
	// This works by creating a new access key for the user's account and storing
	// the private key in localStorage.
	window.walletConnection.requestSignIn(nearConfig.contractName);
}

export function accountId() {
	return {
		accountId: window.accountId,
	};
}

// Council DAO

export function treasuryDAO() {
	return window.treasuryDAO.get_proposals();
}

export function checkStaker(account) {
	return window.treasuryDAO.check_staker({ account });
}

export function getStakers() {
	return window.treasuryDAO.get_stakers();
}

export function getCommunities() {
	return window.treasuryDAO.get_communities();
}

export function getCouncils() {
	return window.treasuryDAO.get_councils();
}

export function getSpecificProposalById(id) {
	return window.treasuryDAO.get_specific_proposal({ id });
}

export function joinDAO(account) {
	return window.treasuryDAO.add_community({ account });
}

export function addCouncil(account) {
	return window.treasuryDAO.add_council({ account });
}

export function createProposal(
	id,
	proposal_type,
	proposal_name,
	description,
	amount,
	duration_days,
	duration_hours,
	duration_min,
	receiver,
) {
	return window.treasuryDAO.create_proposal({
		id,
		proposal_type,
		proposal_name,
		description,
		amount,
		duration_days,
		duration_hours,
		duration_min,
		receiver,
	});
}

export function addVote(id, vote) {
	return window.treasuryDAO.add_vote({ id, vote });
}

// Staking

export function getTotalStaked() {
	return window.rewardPool.get_totalstaked();
}

export function getStaker(account) {
	return window.rewardPool.get_data({ account });
}

export async function depositStake(amount) {
	const account = window.walletConnection.account();

	await account.signAndSendTransaction({
		receiverId: 'lts_token.near',
		actions: [
			transactions.functionCall(
				'ft_transfer',
				{
					receiver_id: 'lightencypool.near',
					amount: String(amount * 100000000),
					memo: '',
				},
				'150000000000000',
				'1',
			),
			transactions.functionCall('stake', { amount }, '150000000000000'),
		],
	});
}

export function unstakeFunction(amount) {
	return window.stake.unstake({ amount });
}

export function withdrawStake(amount) {
	return window.stake.withdraw({ amount });
}

export function withdrawReward(account) {
	return window.rewardPool.withdraw_reward({ account });
}

export function estimateReward(account) {
	return window.rewardPool.calculaterewards({ account, pool: 100 });
}

// Vesting

export function getAllVestors() {
	return window.vesting.get_all_vestors();
}

export function claimLockups(v_id) {
	return window.vesting.refresh({ v_id });
}

export async function addLockup() {
	const account = window.walletConnection.account();

	await account.signAndSendTransaction({
		receiverId: 'lightency_vesting.near',
		actions: [
			transactions.transfer('1000000000000000000000000'),
			transactions.functionCall(
				'add_lockup',
				{ id: uuidv4(), amount_of_token: 100 },
				'150000000000000',
			),
		],
	});
}

// Wallet

export function getLTSBalance() {
	return window.lts.ft_balance_of({ account_id: window.accountId });
}

export function getLTSBalanceByAccountId(account_id) {
	return window.lts.ft_balance_of({ account_id });
}

export function getUSDNBalanceByAccountId(account_id) {
	return window.usdn.ft_balance_of({ account_id });
}

// Energy DAO
export function getProposalsED() {
	return window.energyDAO.get_proposals();
}

export function checkStakerED(account) {
	return window.energyDAO.check_staker({ account });
}

export function getStakersED() {
	return window.energyDAO.get_stakers();
}

export function getCommunitiesED() {
	return window.energyDAO.get_communities();
}

export function getCouncilsED() {
	return window.energyDAO.get_councils();
}

export function getSpecificProposalByIdED(id) {
	return window.energyDAO.get_specific_proposal({ id });
}

export function joinDAOED(account) {
	return window.energyDAO.add_community({ account });
}

export function addCouncilED(account) {
	return window.energyDAO.add_council({ account });
}

export function createProposalED(
	proposal_type,
	proposal_name,
	description,
	amount,
	duration_days,
	duration_hours,
	duration_min,
) {
	return window.energyDAO.create_proposal({
		proposal_type,
		proposal_name,
		description,
		amount,
		duration_days,
		duration_hours,
		duration_min,
	});
}

export function addVoteED(proposal_name, vote) {
	return window.energyDAO.add_vote({ proposal_name, vote });
}

export function getWallet() {
	return window.walletConnection;
}
