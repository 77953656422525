import {
	GET_ALL_VESTORS_SUCCESS,
	GET_ALL_VESTORS_FAIL,
	GET_MY_VESTING_DETAILS_SUCCESS,
	GET_MY_VESTING_DETAILS_FAIL,
} from '../actions/types';

const initialState = {
	isLoading: true,
	vestorsDetails: [],
	myVestingDetails: {},
	message: '',
};

const vesting = (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case GET_ALL_VESTORS_SUCCESS:
			return {
				...state,
				isLoading: false,
				vestorsDetails: payload,
			};
		case GET_ALL_VESTORS_FAIL:
		case GET_MY_VESTING_DETAILS_FAIL:
			return {
				...state,
				isLoading: false,
				message: 'Something went wrong, maybe try againg later',
			};
		case GET_MY_VESTING_DETAILS_SUCCESS:
			return {
				...state,
				isLoading: false,
				myVestingDetails: payload,
			};
		default:
			return state;
	}
};

export default vesting;
