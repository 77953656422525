import {
	CREATE_PROPOSAL_SUCESS,
	GET_COMMUNITIES_FAIL,
	GET_COMMUNITIES_SUCCESS,
	GET_COUNCIL_FAIL,
	GET_COUNCIL_SUCCESS,
	GET_PROPOSALS_FAIL,
	GET_PROPOSALS_SUCCESS,
	GET_SPECIFIC_PROPOSAL_FAIL,
	GET_SPECIFIC_PROPOSAL_SUCCESS,
} from '../actions/types';

const initialState = {
	proposals: [],
	proposal: null,
	communities: [],
	councils: [],
	message: '',
	isLoading: true,
};

const councilDAO = (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case GET_PROPOSALS_SUCCESS:
			return {
				...state,
				isLoading: false,
				proposals: payload,
			};
		case GET_PROPOSALS_FAIL:
			return {
				...state,
				isLoading: false,
			};
		case GET_COMMUNITIES_SUCCESS:
			return {
				...state,
				communities: payload,
				isLoading: false,
			};
		case GET_COMMUNITIES_FAIL:
			return {
				...state,
				isLoading: false,
				message: 'Failed to fetch the list of the community members, maybe try later',
			};
		case GET_COUNCIL_SUCCESS:
			return {
				...state,
				councils: payload,
				isLoading: false,
			};
		case GET_COUNCIL_FAIL:
			return {
				...state,
				isLoading: false,
				messages: 'Failed to fetch the list of the councils members, maybe try later',
			};
		case GET_SPECIFIC_PROPOSAL_SUCCESS:
			return {
				...state,
				proposal: payload,
				isLoading: false,
			};
		case GET_SPECIFIC_PROPOSAL_FAIL:
			return {
				...state,
				isLoading: false,
				message: 'Failed to fetch this specific proposal, maybe try later',
			};
		case CREATE_PROPOSAL_SUCESS:
			return {
				isLoading: false,
			};
		default:
			return state;
	}
};

export default councilDAO;
